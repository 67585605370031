<template>
  <div class="wrap">
    <div class="main">
      <VanCellGroup inset>
        <!-- <van-field label="手机型号" placeholder="请选择" @click="handleClickItem" :model-value="form.phone_model" readonly /> -->
        <van-cell title="手机型号" is-link @click="handleClickItem" :value="form.phone_model"></van-cell>
      </VanCellGroup>
    </div>

    <van-action-sheet cancel-text="取消" close-on-click-action @cancel="flags.phone_model_show = false;"
      v-model:show="flags.phone_model_show" :actions="phone_models" @select="handleChangePhoneModal" />
  </div>
</template>

<script>
// @ is an alias to /src

import { showConfirmDialog, showImagePreview, showLoadingToast, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
export default {
  name: 'Jkbg',
  data() {
    return {
      active: 0,
      phone_models: [],
      flags: {
        phone_model_show: false,
      },
      form: {
        phone_model: '',
      }
    }
  },
  created() {
    this.getListData();
    this.get_user_detail({ sjhm: this.$route.query.sjhm });
  },
  computed: {
    ...mapState(['user_detail']),
  },
  watch: {
    user_detail: {
      handler(newVal) {
        // console.log(newVal);
        this.form.phone_model = newVal.phone_model || '';
      }
    }
  },
  methods: {
    ...mapActions(['get_user_detail']),
    handleChangePhoneModal(e) {
      // console.log(e);
      this.form.phone_model = e.name;
      this.flags.phone_model_show = false;
      this.submit();
    },
    async submit() {
      let hide = showLoadingToast({ message: '操作中...' });
      let { data: res } = await axios.get("/api/api/submit_userinfo", {
        params: {
          sjhm: this.$route.query.sjhm,
          phone_model: this.form.phone_model
        }
      });
      hide.close();
      if (res?.success) {
        showNotify({ type: 'success', message: '编辑成功' });
        setTimeout(() => {
          this.get_user_detail({ sjhm: this.$route.query.sjhm });
        }, 1500)
      }
    },
    handleClickItem() {
      this.flags.phone_model_show = true;
    },
    async getListData() {
      let { data: res } = await axios.get("/api/api/get_phone_model", {
        params: {
          sjhm: this.$route.query.sjhm,
        }
      });
      res.data = res.data.map(m => ({
        name: m.brand + '-' + m.model
      }))
      this.phone_models = res.data;
    }
  },

}
</script>
<style lang="less" scoped>
.wrap {
  .main {
    padding: 24px 0;
  }
}
</style>
